import * as React from "react"
import Logo from './logo'
import { useTranslation } from 'react-i18next';

const SignupHeader = () => {
const { t } = useTranslation()

  return (
    <section className="signup-header">
        <div className="signup-header__logo">
            <Logo url={t("homeurl")}></Logo>
        </div>
        <div className="signup-header__title">
            <h1 data-sal="slide-up" data-sal-duration="500">{t("heading")}</h1>
            <hr data-sal="slide-up" data-sal-duration="500" />
            <h3 data-sal="slide-up" data-sal-duration="500" >{t("subHeading")}</h3>
        </div>
    </section>
  )
}

export default SignupHeader
