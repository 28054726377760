import * as React from "react"
import Layout from '../components/layout'
import BackgroundImage from '../components/backgroundimage'
import SignupHeader from '../components/signupheader'
import Seo from '../components/seo'
import { useTranslation } from 'react-i18next';


// markup
const IndexPage = ({pageContext}) => {

    
  const { t } = useTranslation()
  
  return (
    <Layout>
            <Seo language={pageContext.lang} siteurl={t("siteurl")} title={t("heading")} description={t("subHeading")}></Seo> 
            <SignupHeader></SignupHeader>
            <main>
                <div className={`signup-form`}>
                    <div className="signup-form__inner signup-form__inner--large">
                        <div className="vimeo-wrapper">
                        
                            <iframe src="https://www.youtube.com/embed/-KIDQgs-U5c" className="content" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="JOTUN BİR ARADA" loading="lazy"></iframe>
                        </div>
                    </div>
                </div>

            </main>
            <BackgroundImage></BackgroundImage>
    </Layout>
  )
}

export default IndexPage
