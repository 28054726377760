import * as React from "react"
import { useTranslation } from 'react-i18next';

const Footer = () => {
const { t } = useTranslation()
  return (
    <div className="footer">
        <div className="footer__inner">
            <p>{t("footer.copyright")} | <a href={t("footer.link")} target="_blank" rel="noreferrer">
{t("footer.linkText")}
            </a></p>
        </div>
    </div>
  )
}

export default Footer
