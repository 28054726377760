import * as React from "react"
import Footer from './footer';

import "../assets/scss/styles.scss"

const Layout = ({children}) => {
    
  return (
    <>
    {children}
    <Footer></Footer>
    </>
  )
}

export default Layout
